require('./bootstrap');



import Swiper, { Navigation } from 'swiper';


// Swiper JS: swiperjs.com/get-started
const swiper = new Swiper(".mySwiper", {
	modules: [Navigation],
	// slidesPerView: 4,
	slidesPerView: 'auto',
	spaceBetween: 12,
	// centeredSlides: true,
	grabCursor: true,
	keyboard: {
		enabled: true,
	},
	// pagination: {
	// 	el: ".swiper-pagination",
	// 	clickable: true,
	// },
	navigation: {
		nextEl: ".swiper-btn-next",
		prevEl: ".swiper-btn-prev",
	},
});



